import { render, staticRenderFns } from "./statParamsSetting.vue?vue&type=template&id=2b9a95ff&scoped=true&"
import script from "./statParamsSetting.vue?vue&type=script&lang=js&"
export * from "./statParamsSetting.vue?vue&type=script&lang=js&"
import style0 from "./statParamsSetting.vue?vue&type=style&index=0&id=2b9a95ff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9a95ff",
  null
  
)

export default component.exports