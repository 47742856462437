<template>
  <div class="form">
    <el-form ref="form" label-position="top" :model="form">
      <el-form-item
        v-if="scoreType !== 1 && !isUnionExam"
        label="选择默认参数模板"
      >
        <div class="rowForm">
          <div class="label">选择模板</div>
          <div>
            <el-select
              v-model="paramId"
              placeholder="请选择"
              @change="paramIdChange"
            >
              <el-option
                v-for="item in statsettinglist"
                :key="'statsettinglist' + item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        v-if="!isNewExam && !isUnionExam"
        label="上线设置"
        prop="onlineType"
        :rules="{
          validator: validOnlineType,
          trigger: 'change',
        }"
        class="noLine"
      >
        <el-radio-group v-model="form.onlineType" @change="onlineTypeChange">
          <el-radio :label="1">按比例</el-radio>
          <el-radio :label="2">按人数</el-radio>
          <el-radio :label="3">按有效分</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="!isNewExam && !isUnionExam" class="borderBottomBox">
        <div v-if="form.onlineType === 1">
          <el-form-item
            v-for="(item, index) in form.statOnlineSettings"
            :key="index"
            class="rowForm noLine errorDefine"
            :prop="'statOnlineSettings.' + index + '.value'"
            :rules="{
              validator: (rule, value, callback) =>
                checkValueType(rule, value, callback, index),
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="item.name"
              style="width: 120px"
              size="mini"
              @input="saveCacheStatOnline"
            ></el-input>
            <span> ：比例前</span>
            <el-input
              v-model="item.value"
              style="width: 120px"
              @input="saveCacheStatOnline"
            ></el-input>
            <span>%</span>
            <i class="el-icon-delete" @click="delItem(index)"></i>
          </el-form-item>
        </div>
        <div v-if="form.onlineType === 2">
          <el-form-item
            v-for="(item, index) in form.statOnlineSettings"
            :key="index"
            :prop="'statOnlineSettings.' + index + '.value'"
            class="rowForm noLine"
            :rules="{
              validator: (rule, value, callback) =>
                checkValueType(rule, value, callback, index),
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="item.name"
              style="width: 120px"
              size="mini"
              @input="saveCacheStatOnline"
            ></el-input>
            <span> ： 人数前</span>
            <el-input
              v-model="item.value"
              style="width: 120px"
              @input="saveCacheStatOnline"
            ></el-input>
            <span>名</span>
            <i class="el-icon-delete" @click="delItem(index)"></i>
          </el-form-item>
        </div>
        <div v-if="form.onlineType === 3">
          <el-form-item
            v-for="(item, index) in form.statOnlineSettings"
            :key="index"
            class="rowForm noLine"
            :rules="{
              validator: (rule, value, callback) =>
                checkValueType(rule, value, callback, index),
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="item.name"
              style="width: 120px"
              size="mini"
              @input="saveCacheStatOnline"
            ></el-input>
            <span>，有效分</span>
            <el-input
              v-model="item.value"
              disabled
              style="width: 120px"
              @change="saveCacheStatOnline"
            ></el-input>
            <span>
              <el-button
                type="primary"
                size="mini"
                @click="settingScore(item, index)"
                >设置</el-button
              >
            </span>
            <i class="el-icon-delete" @click="delItem(index)"></i>
          </el-form-item>
        </div>

        <el-button type="primary" class="mb20" @click="addItem">增加</el-button>
        <div v-if="form.onlineType === 2" class="tag mb20">
          <i class="el-icon-warning"></i>
          <span>单科有效分计算方式：</span>
          <div class="tagRadio">
            <el-form-item
              prop="subjectEpType"
              class="noLine noMargin"
              :rules="{
                required: true,
                message: '单科有效分计算方式设置不正确',
                trigger: 'change',
              }"
            >
              <el-radio-group v-model="form.subjectEpType">
                <el-radio :label="1">按单科成绩榜取上线人数</el-radio>
                <el-radio :label="2"
                  >单科榜计算新总分榜，取总分有效分所在行</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- <div class="algorithm-link">
            <i class="el-icon-warning"></i>
            点击了解两种方式的算法
          </div> -->
        </div>
      </div>
      <div v-if="scoreType !== 1 && !isUnionExam" class="title">
        默认临界区间设置（分值）
      </div>
      <div v-if="scoreType !== 1 && !isUnionExam" class="form-box mb30">
        <el-form-item
          v-for="(item, index) in form.statCriticalIntervalSettings"
          :key="'statCriticalIntervalSettings' + index"
          label=""
          :prop="'statCriticalIntervalSettings.' + index + '.value'"
          :rules="{
            required: true,
            validator: (value, rule, callback) =>
              checkCritical(value, rule, callback, index),
            trigger: 'blur',
          }"
        >
          <div class="form-box">
            <div class="form-box-item" style="margin-bottom: 0">
              <div class="label">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <span>{{ item.name }}</span>
                </el-tooltip>
              </div>
              <div class="input">
                <el-input v-model="item.value"></el-input>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="topSection">
        <div class="title">
          前 N 名区间设置
          <el-tooltip effect="dark" placement="top">
            <div slot="content">
              <div>以英文半角逗号" , "表示排名间隔。</div>
              <div>
                例如：对前10名、前50名、前100名进行统计，请输入为：10,50,100
              </div>
            </div>
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </div>
        <div class="tip">
          说明：此设置将会统计前n名（如：前10名、30名、50名）的考生名单。
        </div>
        <template v-if="scoreType !== 1">
          <el-form-item
            class="not-allow-label"
            prop="topSection"
            :rules="{
              required: true,
              validator: checkTopSection,
              trigger: 'blur',
            }"
          >
            <el-input v-model="form.topSection" style="width: 520px"></el-input>
            <span style="margin-left: 8px">名</span>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item
            v-for="(item, index) in form.topSections"
            :key="'topSections' + index"
            class="not-allow-label"
            :prop="'topSections.' + index + '.value'"
            :rules="{
              required: true,
              validator: (rule, value, callback) =>
                checkTopSections(rule, value, callback, index),
              trigger: 'blur',
            }"
          >
            <span style="margin-right: 5px">{{ getName(item.subjectId) }}</span>
            <el-input v-model="item.value" style="width: 520px"></el-input>
            <span style="margin-left: 8px">名</span>
          </el-form-item>
        </template>
      </div>
      <div>
        <div class="title">
          分数段区间设置
          <el-tooltip effect="dark" placement="top">
            <div slot="content">
              <div>示例：</div>
              <div>若分数下限为300，分数上限为500，间隔为50。则：</div>
              <div>第一段：300～350</div>
              <div>第二段：350～400</div>
              <div>第三段：400～450</div>
              <div>依此类推……</div>
              <div>（其中次段考生中将包含位于前段“上限分数值”的考生）</div>
            </div>
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </div>
        <div class="tip">
          说明：此设置将统计“分数下限”～“分数上限”之间的考生名单，并根据间隔划分各区间的考生。
        </div>
        <div v-if="form.statSegmentSettings">
          <el-form-item
            v-for="(item, index) in form.statSegmentSettings.slice(0, 1)"
            :key="'statSegmentSettings' + 'maximum' + index"
            :prop="'statSegmentSettings.' + index + '.maximum'"
            label=""
            class="not-allow-label"
            :rules="{
              validator: (rule, value, callback) =>
                checkTotalScore(rule, value, callback, index),
              trigger: 'blur',
            }"
          >
            <div class="tip">【总分】</div>
            <div class="rowForm">
              <div class="rowForm-item">
                <div>{{ item.score }}</div>
              </div>
              <div class="rowForm-item">
                <div class="label">上限：</div>
                <el-input v-model="item.maximum" style="width: 80px">
                </el-input>
                <span class="append">分</span>
              </div>
              <div class="rowForm-item">
                <div class="label">下限：</div>
                <el-input v-model="item.minimum" style="width: 80px">
                </el-input>
                <span class="append">分</span>
              </div>
              <div class="rowForm-item">
                <div class="label">间隔：</div>
                <el-input v-model="item.gap" style="width: 80px"> </el-input>
                <span class="append">分</span>
              </div>
            </div>
          </el-form-item>
        </div>

        <div class="tip">
          【单科】<el-button
            type="primary"
            style="margin-left: 18px"
            @click="openDialog"
            >比例自动计算</el-button
          >
        </div>

        <div v-if="form.statSegmentSettings" class="border-box mb20">
          <el-form-item
            v-for="(item, index) in form.statSegmentSettings.slice(
              1,
              form.statSegmentSettings.length
            )"
            :key="'statSegmentSettings' + index"
            class="not-allow-label"
            :prop="'statSegmentSettings.' + (index + 1) + '.maximum'"
            label=" "
            :rules="{
              validator: (rule, value, callback) =>
                checkTotalScore(rule, value, callback, index + 1),
              trigger: 'blur',
            }"
          >
            <div class="rowForm">
              <div class="rowForm-item">
                <div class="label">{{ item.name }}：</div>
                <div class="fullScore">{{ item.score }}</div>
              </div>
              <div class="rowForm-item">
                <div class="label">上限：</div>
                <el-input v-model="item.maximum" style="width: 80px">
                </el-input>
                <div class="append">分</div>
              </div>
              <div class="rowForm-item">
                <div class="label">下限：</div>
                <el-input v-model="item.minimum" style="width: 80px">
                </el-input>
              </div>
              <div class="rowForm-item">
                <div class="label">间隔：</div>
                <el-input v-model="item.gap" style="width: 80px"> </el-input>
                <div class="append">分</div>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-if="scoreType !== 1 && !isUnionExam" class="title">
          排名区间设置
        </div>
        <el-form-item
          v-if="scoreType !== 1 && !isUnionExam"
          prop="rankMaximum"
          style="margin-right: 10px"
          :rules="{
            validator: checkRank,
            trigger: 'blur',
          }"
        >
          <div class="rowForm">
            <div class="rowForm-item">
              <div class="label">上限：</div>
              <el-input
                v-model="form.rankMaximum"
                style="width: 80px"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="form.rankMaximum = $event.target.value"
              >
              </el-input>
              <div class="append">名</div>
            </div>
            <div class="rowForm-item">
              <div class="label">下限：</div>
              <el-input
                v-model="form.rankMinimum"
                style="width: 80px"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="form.rankMinimum = $event.target.value"
              >
              </el-input>
              <div class="append">名</div>
            </div>
            <div class="rowForm-item">
              <div class="label">间隔：</div>
              <el-input
                v-model="form.rankGap"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 80px"
                @blur="form.rankGap = $event.target.value"
              >
              </el-input>
              <div class="append">名</div>
            </div>
          </div>
        </el-form-item>
        <div class="title">自定义比率设置</div>
        <div class="tip">
          说明：此设置适用于自定义统计各类比率（如：高分率、优秀率、及格率、低分率等）
        </div>
        <el-radio-group
          v-model="form.ratioType"
          style="margin-bottom: 24px"
          @change="ratioTypeChange"
        >
          <el-radio :label="1"
            >按分数统计
            <span class="point-tip">
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <div>
                    按分数统计时，系统将计算得分小于或大于等于（总分x设定比例）的考生。
                  </div>
                  <div>例：若总分为100分</div>
                  <div>
                    设定“高分率”为大于等于80%（即100*80%=80分），则位于此分值及以上的考生为高分群体。
                  </div>
                  <div>
                    设定“低分率”为小于40%（即100x40%=40分），则低于此分值的考生为低分群体。
                  </div>
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </el-radio>
          <el-radio :label="2"
            >按人数统计
            <span class="point-tip">
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <div>
                    按人数统计时，系统将计算基准分数之前或之后百分比（参与统计全部人数x设定比例）的考生。
                  </div>
                  <div>例：若人数为100人</div>
                  <div>
                    设定“高分率”为前20%（100x20%=前20%），则以前20%考生的最低分为基准，位于此基准之上的考生为高分群体。
                  </div>
                  <div>
                    设定“低分率”为后40%（100x40%=后40%），则以后40%考生的最高分为基准，位于此基准之下的考生为低分群体。
                  </div>
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </el-radio>
        </el-radio-group>
        <div class="border-box mb20">
          <el-form-item
            v-for="(item, index) in form.statRatioSettings"
            :key="'statRatioSettings' + index"
            label=""
            :prop="'statRatioSettings.' + index + '.value'"
            class="noLine"
            :rules="{
              validator: (rule, value, callback) =>
                checkRatioType(rule, value, callback, index),
              trigger: 'change',
            }"
          >
            <div class="rowForm">
              <el-input
                v-model="item.name"
                style="width: 90px"
                size="mini"
              ></el-input>
              <span> 率，</span>
              <div>
                <el-select
                  v-model="item.type"
                  style="width: 90px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="it in options"
                    :key="'type' + it.value"
                    :label="it.label"
                    :value="it.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <span>:</span>
              <div>
                <el-input
                  v-model.number="item.value"
                  style="width: 90px"
                ></el-input>
              </div>
              <span>%</span>
              <i class="el-icon-delete" @click="delRatioItem(index)"></i>
            </div>
          </el-form-item>
          <div class="dashed-btn" @click="addRatioItem">
            <i class="el-icon-plus"></i> 添加比率
          </div>
        </div>
      </div>
      <template v-if="!isNewExam">
        <!-- 2024.04.02 测试冯参说赋分不适用 就隐藏 -->
        <div class="title">
          设置参与全科比率统计的科目
          <el-tooltip
            effect="dark"
            content="若无特殊统计要求，推荐使用默认选项。（新高考赋分统计不适用）"
            placement="top"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </div>
        <div class="tip">
          说明：此设置用于在计算“全科及格率”、“全科优秀率”等涉及到全科判断指标时，对所勾选科目将被纳入计算范畴。
        </div>
        <div class="rowForm mb20">
          <el-button type="text" :disabled="isNewExam" @click="allCheckChange"
            >选择全部</el-button
          >
          <el-button type="text" :disabled="isNewExam" @click="cancleAllChange"
            >取消全部</el-button
          >
        </div>

        <el-form-item v-if="!isNewExam" label="" class="not-allow-label">
          <el-checkbox-group
            v-model="form.allSubjectRatioSubjectIds"
            @change="handleCheckedChange"
          >
            <el-checkbox
              v-for="(item, index) in statSubjects"
              :key="index"
              :label="item.subjectId"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div v-else class="groupSubject">
          <!-- 原始分统计可以用 新高考不适用 -->
          <div class="groupSubject-box">
            <el-checkbox-group disabled>
              <el-checkbox label="语文"> </el-checkbox>
              <el-checkbox label="数学"> </el-checkbox>
              <el-checkbox label="英语"> </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="groupSubject-box">
            <el-checkbox-group disabled>
              <el-checkbox label="历史"> </el-checkbox>
              <el-checkbox label="物理"> </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="groupSubject-box">
            <el-checkbox-group disabled>
              <el-checkbox label="政治"> </el-checkbox>
              <el-checkbox label="地理"> </el-checkbox>
              <el-checkbox label="化学"> </el-checkbox>
              <el-checkbox label="生物"> </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </template>
      <template v-if="isUnionExam">
        <el-form-item label="联考端学生排名是否显示">
          <div class="tip">
            说明：若选择“不显示”，则此次考试在联考端的考试管理、所有数据分析产品（包括导出数据包）均不显示学生排名
          </div>
          <el-radio-group
            v-model="form.isUnionDisplayStudentRank"
            @change="studentRankChange"
          >
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="成绩排序方式"
          prop="schoolReportStudentRankListOrder"
        >
          <el-radio-group v-model="form.schoolReportStudentRankListOrder">
            <el-radio :label="1" :disabled="form.isUnionDisplayStudentRank == 0"
              >按排名排序</el-radio
            >
            <el-radio :label="2">按考号排序</el-radio>
            <el-radio :label="3">按姓名排序</el-radio>
            <el-radio :label="4">随机排序</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="学校端学生排名是否显示">
          <div class="tip">
            说明：如选择“不显示”，则此次考试在学校端的考试管理、所有数据分析产品（包括导出数据包）均不显示学生排名；不影响联考端
          </div>
          <el-radio-group v-model="form.isSchoolDisplayStudentRank">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item label="学校端学生成绩是否显示">
          <div class="tip">
            说明：如选择“不显示”，则此次考试在学校端的考试管理、所有数据分析产品（包括导出数据包）均不显示学生成绩；不影响联考端
          </div>
          <el-radio-group v-model="form.isSchoolDisplayStudentScore">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </el-form-item> -->
      </template>
      <el-form-item
        v-if="$route.query.ruleId == 0 && $route.query.scoreType != 1"
        label="等级区间设置"
      >
        <div class="rowForm mb20">
          <el-switch
            v-model="form.levelSegmentSwitch"
            :active-value="1"
            :inactive-value="0"
            active-text="开启等级区间设置"
            inactive-text="关闭等级区间设置"
          >
          </el-switch>
        </div>
        <el-radio-group
          v-if="form.levelSegmentSwitch == 1"
          v-model="form.levelSegmentType"
        >
          <el-radio :label="1">按分数比例</el-radio>
          <el-radio :label="2">按人数比例</el-radio>
          <el-radio :label="3">按分数</el-radio>
          <el-radio :label="4">按人数 </el-radio>
        </el-radio-group>
        <div
          v-if="form.levelSegmentType == 1 && form.levelSegmentSwitch == 1"
          class="ps mb20"
        >
          以","分隔多个等级名称和等级百分比，等级百分比与等级的名称一一对应。如：等级的名称输入"A,B,C"，等级百分比输入"90,80"。计算规则为：①计算满分*90%的有效分=n1，则满分≥考生分数≥n1，记为“A”；②计算满分*80%的有效分=n2，则n1＞考生分数≥n2，记为“B”；③n2＞考生分数≥0分，记为“C”
        </div>
        <div
          v-if="form.levelSegmentType == 2 && form.levelSegmentSwitch == 1"
          class="ps mb20"
        >
          以","分隔多个等级名称和等级百分比，等级百分比与等级的名称一一对应。如：等级的名称输入"A,B,C"，等级百分比输入"10,20"。计算规则为：①计算排名第10%的考生（小数取整）的有效分=n1，则满分≥考生分数≥n1，记为“A”；②计算排名第20%的考生（小数取整）的有效分=n2，则n1＞考生分数≥n2，记为“B”；③n2＞考生分数≥0分，记为“C”
        </div>
        <div v-if="form.levelSegmentSwitch == 1" class="rowForm wrap">
          <div class="level-item">
            <div class="header">学科</div>
            <div class="col">等级名称</div>
            <div
              v-if="[1, 2].includes(form.levelSegmentType)"
              class="col last-col"
            >
              等级百分比
            </div>
            <div v-if="form.levelSegmentType == 3" class="col last-col">
              分数
            </div>
            <div v-if="form.levelSegmentType == 4" class="col last-col">
              人数
            </div>
          </div>

          <el-form-item
            v-for="(item, index) in form.statLevelSegmentSettings"
            :key="'statLevelSegmentSettings' + index"
            class="noLine mb0"
            :prop="'statLevelSegmentSettings.' + index + '.value'"
            :rules="{
              validator: (rule, value, callback) =>
                checkLevelSegment(rule, value, callback, index),
            }"
          >
            <div class="level-item">
              <div
                :class="[
                  'header',
                  index === form.statLevelSegmentSettings.length - 1
                    ? 'last-row'
                    : '',
                ]"
              >
                {{ item.subjectId === 0 ? "总分" : getName(item.subjectId) }}
              </div>
              <div
                :class="[
                  'col',
                  index === form.statLevelSegmentSettings.length - 1
                    ? 'last-row'
                    : '',
                ]"
              >
                <el-input v-model="item.name" style="width: 90%"></el-input>
              </div>
              <div
                :class="[
                  'col',
                  'last-col',
                  index === form.statLevelSegmentSettings.length - 1
                    ? 'last-row'
                    : '',
                ]"
              >
                <el-input v-model="item.value" style="width: 90%">
                  <span
                    v-if="[1, 2].includes(form.levelSegmentType)"
                    slot="suffix"
                    >%</span
                  >
                </el-input>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form-item>
      <template v-if="!isUnionExam">
        <el-form-item label="学生排名是否显示">
          <div class="tip">
            说明：此设置用于对所有数据分析功能、数据导出功能设定是否显示学生排名信息。
          </div>
          <el-radio-group
            v-model="form.isDisplayRank"
            @change="isDisplayRankChange"
          >
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">
              <el-tooltip
                class="item"
                effect="dark"
                content="未开启学生排名"
                placement="top"
                :disabled="form.isDisplayRank !== 0"
              >
                <span> 不显示</span>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="成绩榜导出排序">
          <div class="tip">
            说明：此设置用于对成绩榜数据导出时按照选定条件调整排序。
          </div>
          <el-radio-group v-model="form.rankListOrder">
            <el-radio :label="1" :disabled="form.isDisplayRank === 0"
              >按排名排序</el-radio
            >
            <el-radio :label="2">按考号排序</el-radio>
            <el-radio :label="3">按姓名排序</el-radio>
            <el-radio :label="4">随机排序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对比考试设置">
          <div class="tip">
            说明：此设置仅对学生排名增幅模块有效，不影响发展性分析模块。若选择了联考端不允许显示学生排名的考试作为对比考试，则报告中将不显示学生排名增幅等相关内容。
          </div>
          <el-select
            v-model="form.compareId"
            style="width: 388px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in compareList"
              :key="'compareList' + item.id"
              :label="compareExamLabel(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="学生答题卡打印设置">
        <div class="flex" style="margin-bottom: 24px">
          <div style="margin-bottom: 0.5rem; margin-right: 18px">总分分值</div>
          <el-radio-group v-model="form.sheetPrintScore">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </div>
        <div class="flex" style="margin-bottom: 24px">
          <div style="margin-bottom: 0.5rem; margin-right: 18px">小题分值</div>
          <el-radio-group v-model="form.sheetPrintQuestionScore">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </div>
        <div class="flex" style="margin-bottom: 24px">
          <div style="margin-bottom: 0.5rem; margin-right: 18px">阅卷教师</div>
          <el-radio-group v-model="form.sheetPrintTeacher">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </div>
        <div class="flex" style="margin-bottom: 24px">
          <div style="margin-bottom: 0.5rem; margin-right: 18px">打分批注</div>
          <el-radio-group v-model="form.sheetPrintNotes">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </div>
        <div class="flex" style="margin-bottom: 24px">
          <div style="margin-bottom: 0.5rem; margin-right: 18px">
            客观题批注
          </div>
          <el-radio-group v-model="form.sheetPrintObjectNotes">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0"> 不显示 </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item
        v-if="$route.query.examType == 1"
        label="学校代码设置（统计分析按学校代码排序）"
      >
        <div class="schoolList">
          <div
            v-for="(item, index) in form.schoolCodes"
            :key="index"
            class="school-item"
          >
            {{ item.name }}
            <el-input
              v-model="item.code"
              style="width: 120px; margin-left: 8px"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <div class="save">
        <el-button type="primary" :loading="btnLoading" @click="save"
          >保存</el-button
        >
      </div>
    </el-form>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :align="title === '使用比例计算' ? 'center' : ''"
      :width="title === '使用比例计算' ? '30%' : '40%'"
      :before-close="handleClose"
    >
      <template v-if="title === '有效分设置'">
        <div class="form-box">
          <div
            v-for="(item, index) in statOnlineEpSettings"
            :key="'statOnlineEpSettings' + index"
            class="form-box-item"
          >
            <div class="label">{{ item.name }}：</div>
            <div class="input">
              <el-input v-model="item.value"></el-input>
            </div>
          </div>
        </div>
      </template>
      <template v-if="title === '使用比例计算'">
        <div class="tip" style="text-align: center">
          对<span>所有科目</span>采用如下参数计算
        </div>
        <div class="sliders">
          <div class="left">
            <div style="margin-right: 10px">下限</div>
            <el-input
              v-model.number="min"
              style="width: 60px"
              @input="validMin"
            ></el-input>
            <div class="append">%</div>
          </div>
          <div class="slider">
            <el-slider v-model="sliderValue" disabled range> </el-slider>
          </div>
          <div class="left">
            <div style="margin-right: 10px">上限</div>
            <el-input
              v-model.number="max"
              style="width: 60px"
              @input="validMax"
            ></el-input>
            <div class="append">%</div>
          </div>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <template v-if="title === '有效分设置'">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </template>
        <template v-if="title === '使用比例计算'">
          <div class="slider-footer">
            <div class="errorMessage">{{ errorMessage }}</div>
            <div class="slider-btns">
              <el-button @click="handleClose">取 消</el-button>
              <el-button
                type="primary"
                :disabled="errorMessage"
                @click="compute"
                >确 定</el-button
              >
            </div>
          </div>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getstatsetting,
  // getstatcomparelist,
  setstatsetting,
  getstatsettinglist,
  changestatsetting,
} from "@/core/api/academic/statistics";
import { getstatschoolcomparelist } from "@/core/api/academic/common";
import validMixin from "../mixin/statParamsSetting";
import { mapState } from "vuex";
export default {
  name: "StatParamsSetting",
  mixins: [validMixin],
  data() {
    return {
      title: "",
      statsettinglist: [], // 统计参数模板列表
      allCheck: false,
      reverse: false,
      cancleAll: false,
      isIndeterminate: false,
      btnLoading: false,
      paramId: "",
      min: 0,
      max: 100,
      checkboxList: [],
      options: [],
      ruleId: this.$route.query.id,
      form: {
        statOnlineSettings: [],
        levelSegmentType: 2,
        ratioType: 1,
        allSubjectRatioSubjectIds: [],
        onlineType: 1,
        levelSegmentSwitch: 1,
        rankMaximum: 50,
        rankMinimum: 50,
        rankGap: 50,
        topSections: [],
      },
      sliderValue: [0, 100],
      compareList: [],
      dialogVisible: false,
      itemIndex: 0,
      statSubjects: [],
      stat: {},
      older: [],
      statOnlineEpSettings: [],
      onlineSubjects: [],
      isUnionExam: false,
    };
  },
  computed: {
    ...mapState({
      statOnlineSettings: (state) => state.report.statOnlineSettings,
      scoreType: (state) => state.report.scoreType,
      isNewExam: (state) => state.report.isNewExam,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    checkLevelSegment(rule, value, callback, index) {
      const { name } = this.form.statLevelSegmentSettings[index];
      const reg =
        /^[\u4e00-\u9fa5a-zA-Z0-9+-]+(,[\u4e00-\u9fa5a-zA-Z0-9+-]+)*$/;
      const regNum = [1, 2].includes(this.form.levelSegmentType)
        ? /^([\d.\d,])*([\d.\d]+)$/
        : /^([\d,])*([\d]+)$/;
      let arr = [name, value].filter((item) => item);
      if (arr.length === 2) {
        if (!reg.test(name) || !regNum.test(value)) {
          if ([1, 2].includes(this.form.levelSegmentType)) {
            callback(new Error("请按照说明格式填写"));
          } else {
            if (!regNum.test(value)) {
              callback(new Error("请填写整数"));
            } else {
              callback(new Error("请按照说明格式填写"));
            }
          }
        } else {
          let nameArr = name.split(",");
          let valueArr = value.split(",");
          if (nameArr.length - 1 != valueArr.length) {
            callback(new Error("名称和百分比没能相互对应"));
          } else {
            const errorArr = valueArr.filter((item, index) => {
              if (index + 1 < valueArr.length) {
                if (this.form.levelSegmentType == 1) {
                  if (Number(item) < Number(valueArr[index + 1])) {
                    return item;
                  }
                } else if (this.form.levelSegmentType == 2) {
                  if (Number(item) > Number(valueArr[index + 1])) {
                    return item;
                  }
                }
              }
            });
            if (errorArr.length) {
              if (this.form.levelSegmentType == 1) {
                callback(new Error("名称和百分比没能相互对应，应由大到小"));
              } else if (this.form.levelSegmentType == 2) {
                callback(new Error("名称和百分比没能相互对应，应由小到大"));
              }
            } else {
              callback();
            }
          }
        }
      } else {
        callback();
      }
    },
    checkTopSection(rule, value, callback) {
      let reg = /^([0-9,])*([0-9]+)$/;
      if (!value) {
        callback(new Error("请输入前N名区间"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确格式"));
        } else {
          callback();
        }
      }
    },
    checkTopSections(rule, value, callback, index) {
      let reg = /^([0-9,])*([0-9]+)$/;
      let data = this.form.topSections[index];
      if (!data.value) {
        callback(new Error(`请输入${this.getName(data.subjectId)}前N名区间`));
      } else {
        if (!reg.test(data.value)) {
          callback(new Error("请输入正确格式"));
        } else {
          callback();
        }
      }
    },
    isDisplayRankChange() {
      if (this.form.isDisplayRank === 0) {
        this.form.rankListOrder = 2;
      }
    },
    paramIdChange() {
      changestatsetting({
        paramId: this.paramId,
        id: this.$route.query.id,
      }).then((res) => {
        this.formateData(res);
      });
    },
    openDialog() {
      this.dialogVisible = true;
      this.title = "使用比例计算";
    },
    getstatsettinglistdata() {
      const { level, year } = this.$route.query;
      getstatsettinglist({ level: level, year: year }).then((res) => {
        this.statsettinglist = res.data.data;
        const result = res.data.data.find(
          (item) => item.id === this.form.paramId
        );
        if (result) {
          this.paramId = this.form.paramId;
        } else {
          this.paramId = null;
        }
      });
    },
    onlineTypeChange() {
      if (this.form.onlineType == 2) {
        this.form.subjectEpType = 1;
      } else {
        this.$nextTick(() => {
          this.$set(this.form, "subjectEpType", null);
        });
      }
      if (this.statOnlineSettings[this.form.onlineType - 1]) {
        this.form.statOnlineSettings = [
          ...this.statOnlineSettings[this.form.onlineType - 1],
        ];
      } else {
        this.form.statOnlineSettings = [];
      }
    },
    saveCacheStatOnline() {
      this.$store.commit("report/SET_STATONLINESETTINGS", {
        index: this.form.onlineType - 1,
        value: [...this.form.statOnlineSettings],
      });
    },
    async getStatCompareList({ level, year }) {
      const res = await getstatschoolcomparelist({
        examLevel: level,
        examYear: year,
        statId: this.$route.query.id,
      });
      this.compareList = [{ name: "不对比", id: 0 }, ...res.data.data];
      const compareIdResult = this.compareList.find(
        (item) => item.id == this.form.compareId
      );
      if (!compareIdResult) {
        if (res.data.data.length) {
          this.form.compareId = this.compareList[1].id;
        } else {
          this.form.compareId = 0;
        }
        this.save("update");
      }
    },
    async getData() {
      const res = await getstatsetting({ id: this.$route.query.id });

      this.formateData(res);
    },
    compareExamLabel(item) {
      if (item) {
        if (item.name && item.examDate) {
          return item.name + "（" + item.examDate + "）";
        } else {
          return item.name;
        }
      }
    },
    formateData(res) {
      let topSectionsData = res.data.data.topSections.filter(
        (item) => item.subjectId != 0
      );
      this.form = {
        ...this.form,
        ...res.data.data,
        levelSegmentSwitch: Number(res.data.data.levelSegmentSwitch),
        topSections: topSectionsData.map((item) => ({
          subjectId: item.subjectId,
          value: item.value,
        })),
        schoolReportStudentRankListOrder:
          res.data.data.stat.schoolReportStudentRankListOrder,
      };

      this.$forceUpdate();
      if (this.$route.query.examType == 1) {
        // 联考没有等级区间设置 默认关闭
        this.isUnionExam = true;
        this.form.levelSegmentSwitch = 0;
      } else {
        this.isUnionExam = false;
      }
      this.saveCacheStatOnline();
      // if (!this.form.compareId) {
      //   this.form.compareId = "";
      // }
      const { statSubjects, stat, ratioType } = res.data.data;
      if (this.$route.query.examDate != 1) {
        // 2024/9/23尹鸿钧 说 联考不调用此接口
        this.getStatCompareList(stat);
      }
      this.form.ratioType = ratioType === 0 ? 1 : ratioType;
      this.ratioTypeChange();
      this.onlineSubjects = [
        { name: "总分", isTotal: 0, subjectId: 0 },
        ...statSubjects,
      ];
      this.stat = stat;
      this.form.statCriticalIntervalSettings.forEach((item) => {
        if (item.subjectId === 0) {
          this.$set(item, "name", "总分");
        } else {
          const data = statSubjects.find(
            (it) => it.subjectId == item.subjectId
          );
          this.$set(item, "name", data.name);
        }
      });
      this.form.statSegmentSettings.forEach((item) => {
        if (item.subjectId === 0) {
          this.$set(item, "name", "总分");
          this.$set(item, "score", stat.score);
        } else {
          const data = this.onlineSubjects.find(
            (it) => it.subjectId == item.subjectId
          );
          this.$set(item, "name", data.name);
          this.$set(item, "score", data.score);
        }
      });

      this.getstatsettinglistdata();
      this.initStatSubjectList();
      this.$forceUpdate();
    },
    getName(id) {
      const data = this.onlineSubjects.find((item) => item.subjectId == id);
      return data.name;
    },
    getSubjectValue(id, field) {
      if (id) {
        const data = this.onlineSubjects.find((item) => id == item.subjectId);
        return data[field];
      } else {
        const data =
          (field === "name" && "总分") ||
          (field === "score" && this.stat.score);
        return data;
      }
    },
    jud(valueArr) {
      const arr = valueArr.map(Number);
      let numArr = [];
      for (let i = 0; i < arr.length - 1; i++) {
        numArr.push(arr[i + 1] - arr[i]);
      }
      let res = numArr.every((item) => numArr[0] === item);
      if (res) {
        return true;
      } else {
        return false;
      }
    },

    initStatSubjectList() {
      const arr = this.form.statSubjects.map((item) => {
        return {
          name: item.name,
          isTotal: 0,
          subjectId: item.subjectId,
          value: "",
          score: item.score,
        };
      });
      this.statSubjects = [...arr];
    },
    allCheckChange() {
      this.form.allSubjectRatioSubjectIds = this.statSubjects.map(
        (item) => item.subjectId
      );
    },

    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.allCheck = checkedCount === this.statSubjects.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.statSubjects.length;
    },
    studentRankChange(value) {
      if (value == 0) {
        this.form.schoolReportStudentRankListOrder = 2;
      } else {
        this.form.schoolReportStudentRankListOrder = 1;
      }
      this.$forceUpdate();
    },
    reverseChange() {
      const { allSubjectRatioSubjectIds } = this.form;
      const subjectIds = this.statSubjects.map((item) => item.subjectId);
      const reverseArr = subjectIds.filter(
        (item) => !allSubjectRatioSubjectIds.includes(item)
      );
      this.form.allSubjectRatioSubjectIds = reverseArr;
    },
    cancleAllChange() {
      this.form.allSubjectRatioSubjectIds = [];
    },

    delItem(index) {
      this.form.statOnlineSettings.splice(index, 1);
    },
    addItem() {
      if (this.form.onlineType) {
        this.form.statOnlineSettings.push({
          name: "",
          value: "",
        });
      } else {
        this.$message.error("请选择统计参数设置方式");
      }
    },
    delRatioItem(index) {
      this.form.statRatioSettings.splice(index, 1);
    },
    addRatioItem() {
      this.form.statRatioSettings.push({
        name: "",
        type: 1,
        value: "",
      });
    },
    settingScore(item, index) {
      this.itemIndex = index;
      if (item.statOnlineEpSettings) {
        // console.log(item.statOnlineEpSettings);
        this.statOnlineEpSettings = item.statOnlineEpSettings.map((it) => {
          this.$set(it, "name", this.getName(it.subjectId));
          return it;
        });
        this.dialogVisible = true;
        this.title = "有效分设置";
      } else {
        if (item.name && !item.value) {
          this.statOnlineEpSettings = this.onlineSubjects.map((item) => {
            return {
              name: item.name,
              isTotal: 0,
              subjectId: item.subjectId,
              value: 0,
              score: item.score,
            };
          });
          this.title = "有效分设置";
          this.dialogVisible = true;
        } else if (item.name && item.value) {
          let str = item.value.slice(0, item.value.length - 1);
          let arr = str.split(";");
          this.statOnlineEpSettings = arr.map((it) => {
            return {
              name: it.match(/(\S*):/)[1],
              value: it.match(/:(\S*)/)[1],
            };
          });
          this.title = "有效分设置";
          this.dialogVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: "请先输入上线名称",
            type: "error",
          });
        }
      }
    },
    validOnlineType(rule, value, callback) {
      if (!value) {
        callback(new Error("请选择上线设置方式"));
      } else if (!this.form.statOnlineSettings.length) {
        callback(new Error("请填写上线设置参数"));
      } else {
        callback();
      }
    },
    checkValueType(rule, value, callback, index) {
      const { onlineType } = this.form;
      let reg = null;
      if (onlineType === 1) {
        reg = /^[0-9]+(.[0-9]{2})?$/;
        if (!reg.test(value)) {
          callback(new Error("比例为数字且保留两位小数"));
        } else {
          callback();
        }
      } else if (onlineType === 2) {
        reg = /(^[1-9]\d*$)/;
        if (!reg.test(value)) {
          callback(new Error("人数只能为正整数"));
        } else {
          callback();
        }
      } else if (onlineType === 3) {
        let data = this.form.statOnlineSettings[index];
        if (data) {
          if (!data.name) {
            callback(new Error("请输入有效分名称"));
          } else if (!data.value) {
            callback(new Error("请设置有效分区间"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    },
    handleClose() {
      this.statOnlineEpSettings = this.statSubjects.map((item) => {
        return { name: item.name, score: "" };
      });
      this.dialogVisible = false;
    },
    ratioTypeChange() {
      if (this.form.ratioType === 1) {
        this.options = [
          {
            label: "分数≥",
            value: 1,
          },
          {
            label: "分数＜",
            value: 2,
          },
        ];
      } else {
        this.options = [
          {
            label: "人数前",
            value: 1,
          },
          {
            label: "人数后",
            value: 2,
          },
        ];
      }
    },
    compute() {
      if (!this.errorMessage) {
        if (this.stat.score) {
          this.form.statSegmentSettings.forEach((item) => {
            this.$set(
              item,
              "maximum",
              Math.ceil((Number(item.score) / 100) * Number(this.max))
            );
          });
        }
        if (this.min) {
          this.form.statSegmentSettings.forEach((item) => {
            this.$set(
              item,
              "minimum",
              Math.ceil((Number(item.score) / 100) * Number(this.min))
            );
          });
        }
        this.dialogVisible = false;
      }
    },
    checkTotalScore(rule, value, callback, index) {
      const { minimum, maximum, gap, score } =
        this.form.statSegmentSettings[index];
      if (score === 0) {
        callback(new Error("请去考试管理移除该科目"));
      } else if (
        (minimum || String(minimum) == "0") &&
        (maximum || String(minimum) == "0") &&
        (gap || String(gap) == "0")
      ) {
        if (Number(minimum) > Number(maximum)) {
          callback(new Error("下限不能大于上限"));
        }
        if (minimum > score || maximum > score || gap > score) {
          callback(new Error("不能超过满分"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请全部填写参数"));
      }
    },
    confirm() {
      let reg = /^[0-9]+(.[0-9]{1})?$/;
      let arr = this.statOnlineEpSettings.filter((item) => {
        if (!reg.test(item.value)) {
          this.$message({
            showClose: true,
            message: "请输入非负数且不超过1位小数的分数",
            type: "error",
          });

          return item;
        } else if (Number(item.value) > item.score) {
          this.$message({
            showClose: true,
            message: `${item.name} 超过满分 （满分为${item.score}）`,
            type: "error",
          });
          return item;
        }
      });
      if (!arr.length) {
        this.dialogVisible = false;
        this.form.statOnlineSettings[this.itemIndex].value =
          this.statOnlineEpSettings.reduce((total, item) => {
            return total + `${item.name}:${item.value};`;
          }, "");
        this.form.statOnlineSettings[this.itemIndex].statOnlineEpSettings = [
          ...this.statOnlineEpSettings,
        ];
        this.initStatSubjectList();
      }
    },
    save(type) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          if (this.paramId) {
            this.form.paramId = this.paramId;
          } else {
            this.form.paramId = null;
          }
          if (this.form.levelSegmentSwitch == 0) {
            this.$delete(this.form, "statLevelSegmentSettings");
          }
          setstatsetting(this.form)
            .then(() => {
              this.btnLoading = false;
              if (type != "update") {
                this.$message.success("保存成功");
                this.getData();
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          setTimeout(() => {
            let errorNodes = document.querySelectorAll(".is-error");
            let top =
              errorNodes[0].offsetTop - 100 > 0
                ? errorNodes[0].offsetTop - 100
                : 0;
            window.scrollTo({
              top: top,
              behavior: "smooth",
            });
          }, 100);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.form {
  color: #0a1119;
  ::v-deep .el-form-item__label {
    margin-bottom: 18px;
    font-size: 14px;
    color: #0a1119;
    font-weight: 550;
    padding: 8px !important;
    height: 36px;
    width: 100%;
    line-height: 36px;
    background: #f3f8ff;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  ::v-deep .el-form-item__content {
    line-height: 1;
  }

  .rowForm {
    display: flex;
    align-items: center;

    .rowForm-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    span {
      margin: 0 8px;
    }

    i {
      cursor: pointer;
      color: #f56c6c;
    }

    .label {
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  .align-start {
    align-items: flex-start;
  }

  .ps {
    margin: 18px 0;
    color: #b8b8b8;
    line-height: 1.5;
    font-style: 14px;

    span {
      color: #ff3b3b;
    }
  }

  .form-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .form-box-item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      margin-bottom: 30px;
      border: 1px dashed #d8d8d8;

      .label {
        width: 101px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-right: 1px dashed #d8d8d8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .input {
        height: 60px;
        line-height: 60px;
        width: 110px;
        padding: 0 8px;

        input {
          margin-left: 9px;
        }
      }
    }
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .mb18 {
    margin-bottom: 18px;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .noMargin {
    margin: 0;
  }

  .border-box {
    // border-bottom: 1px solid #dfdfdf;
    box-sizing: border-box;
    // padding-bottom: 30px;
  }

  .tag {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border: 1px dashed #2474ed;
    background-color: #f2f8fe;
    font-style: 14px;
    flex-wrap: wrap;
    color: #737677;

    i {
      color: #2474ed;
      margin-right: 10px;
    }

    span {
      margin-right: 25px;
    }

    .tagRadio {
      margin-right: 25px;
    }
    ::v-deep .el-radio {
      margin-bottom: 0;
    }

    .algorithm-link {
      color: #2474ed;
      cursor: pointer;
    }
  }
}

.el-form-item {
  margin-bottom: 24px;
}

.title {
  margin-bottom: 18px;
  font-size: 14px;
  color: #0a1119;
  font-weight: 550;
  padding: 8px;
  height: 36px;
  width: 100%;
  line-height: 36px;
  background: #f3f8ff;
  display: flex;
  align-items: center;
  border-radius: 4px;
  i {
    color: #2474ed;
    font-size: 16px;
    cursor: pointer;
    margin-left: 8px;
  }
}

.level-item {
  font-style: 14px;
  color: #0a1119;
  text-align: center;
  margin-bottom: 24px;
  .header {
    width: 154px;
    background: #f5f7fa;
    height: 44px;
    line-height: 44px;
    border-left: 1px solid #ebecee;
    border-top: 1px solid #ebecee;
  }
  .col {
    width: 154px;
    height: 44px;
    color: #3e4551;
    line-height: 44px;
    border-left: 1px solid #ebecee;
    border-top: 1px solid #ebecee;
  }
  .last-col {
    border-bottom: 1px solid #ebecee;
  }
  .last-row {
    border-right: 1px solid #ebecee;
  }
}

.borderBottomBox {
  box-sizing: border-box;
  // padding-bottom: 20px;
  line-height: 1;
}

.save {
  text-align: center;
}

.tagRadio {
  ::v-deep .el-form-item__error {
    top: 75%;
    left: 25px;
  }
}

.topSection {
}
.not-allow-label {
  margin-bottom: 18px !important;
  ::v-deep .el-form-item__label {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.tip {
  margin-bottom: 18px;
  color: #3e4551;
  font-size: 14px;
  font-weight: 400;
  span {
    color: #2474ed;
  }
}
.append {
  margin-left: 15px;
}
.mb0 {
  margin-bottom: 0;
}
.sliders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  .left {
    display: flex;
    align-items: center;
  }
  .slider {
    flex: 1;
    box-sizing: border-box;
    padding: 0 14px;
  }
  .right {
    display: flex;
    align-items: center;
  }
}
.slider-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .errorMessage {
    font-size: 14px;
    color: #f56c6b;
  }
}
.fullScore {
  width: 30px;
  text-align: center;
}
.point-tip {
  font-size: 16px;
  color: #2474ed;
  margin-left: 5px;
  cursor: pointer;
}
.dashed-btn {
  width: 380px;
  text-align: center;
  color: #0a1119;
  border: 1px dashed #d5d6db;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  i {
    font-size: 16px;
    color: #0a1119;
    margin-right: 5px;
  }
}
.groupSubject {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  &-box {
    margin-right: 18px;
    box-sizing: border-box;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    ::v-deep .el-checkbox-group {
      display: flex;
      align-items: center;
      height: 100%;
      .el-checkbox {
        margin-right: 24px !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
.slider-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.schoolList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .school-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    margin-right: 24px;
  }
}
</style>
