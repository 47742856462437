const reg = /^\d+(?:\.\d{0,2})?$/;
let mixin = {
  methods: {
    validMin() {
      if (!reg.test(this.min)) {
        this.errorMessage = "格式不正确，请输入数字";
      } else if (this.min < 0 || this.min > 100) {
        this.errorMessage = "请输入0-100的数字";
      } else {
        this.errorMessage = null;
        this.sliderValue = [this.min, this.max];
      }
    },
    validMax() {
      if (!reg.test(this.max)) {
        this.errorMessage = "格式不正确，请输入数字";
      } else if (this.max < 0 || this.max > 100) {
        this.errorMessage = "请输入0-100的数字";
      } else {
        this.errorMessage = null;
        this.sliderValue = [this.min, this.max];
      }
    },
    validOnlineType(rule, value, callback) {
      if (!value) {
        callback(new Error("请选择统计参数设置方式"));
      } else {
        callback();
      }
    },
    checkRank(rule, value, callback) {
      if (this.form.rankMinimum === null) {
        callback(new Error("请输入排名区间设置-下限"));
      } else if (this.form.rankMaximum === null) {
        callback(new Error("请输入排名区间设置-上限"));
      } else if (this.form.rankGap === null) {
        callback(new Error("请输入排名区间设置-间隔（正整数）"));
      } else if (
        Number(this.form.rankMinimum) <= Number(this.form.rankMaximum)
      ) {
        callback(new Error("排名区间下限必须大于上限"));
      } else if (
        Number(this.form.rankMinimum) <= 0 ||
        Number(this.form.rankMaximum) <= 0 ||
        Number(this.form.rankGap) <= 0
      ) {
        callback(new Error("排名区间必须大于0"));
      } else {
        callback();
      }
    },
    async checkLevelSegment(rule, value, callback, index) {
      const { name } = this.form.statLevelSegmentSettings[index];
      const reg = /^([\u0391-\uFFE5\d\w,])*([\u0391-\uFFE5\d\w]+)$/;
      const regNum = /^([\d.\d,])*([\d.\d]+)$/;
      if (name && value) {
        if (!reg.test(name) || !regNum.test(value)) {
          callback(new Error("请按照说明格式填写"));
        } else {
          let nameArr = name.split(",");
          let valueArr = value.split(",");
          if (nameArr.length - 1 != valueArr.length) {
            callback(new Error("名称和百分比没能相互对应"));
          } else {
            const errorArr = await valueArr.filter((item, index) => {
              if (index + 1 < valueArr.length) {
                if (this.form.levelSegmentType == 1) {
                  if (Number(item) < Number(valueArr[index + 1])) {
                    return item;
                  }
                } else if (this.form.levelSegmentType == 2) {
                  if (Number(item) > Number(valueArr[index + 1])) {
                    return item;
                  }
                }
              }
            });
            if (errorArr.length) {
              if (this.form.levelSegmentType == 1) {
                callback(new Error("名称和百分比没能相互对应，应由大到小"));
              } else if (this.form.levelSegmentType == 2) {
                callback(new Error("名称和百分比没能相互对应，应由小到大"));
              }
            } else {
              callback();
            }
          }
        }
      } else {
        callback();
      }
    },
    checkTopSection(rule, values, callback, index) {
      let value = this.form.topSections[index].value;
      let reg = /^([0-9,])*([0-9]+)$/;
      if (!value) {
        callback(new Error("请输入前N名区间"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确格式"));
        } else {
          callback();
        }
      }
    },

    checkCritical(rule, values, callback, index) {
      let { value } = this.form.statCriticalIntervalSettings[index];
      let reg = /^([0-9,])*([0-9]+)$/;
      if (!value) {
        callback(new Error("请填写默认临界区间"));
      } else if (Number(value) === 0) {
        callback(new Error("默认临界区间不能为0"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("格式不正确，分值只能为数字"));
        } else {
          callback();
        }
      }
    },
    checkValueType(rule, value, callback) {
      const { onlineType } = this.form;
      let reg = null;
      if (onlineType === 1) {
        reg = /^[0-9]+(.[0-9]{2})?$/;
        if (!reg.test(value)) {
          callback(new Error("比例为数字且保留两位小数"));
        } else {
          callback();
        }
      } else if (onlineType === 2) {
        reg = /(^[1-9]\d*$)/;
        if (!reg.test(value)) {
          callback(new Error("人数只能为正整数"));
        } else {
          callback();
        }
      }
    },
    checkRatioType(rule, value, callback, index) {
      setTimeout(() => {
        const { name, value: val } = this.form.statRatioSettings[index];
        const reg = /(^[1-9]\d*$)/;
        if (name && val) {
          if (!reg.test(val) || val > 100) {
            callback(new Error("比率只能是大于0小于100的正数"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请按顺序填写比率"));
        }
      }, 100);
    },
    checkTotalScore(rule, value, callback, index) {
      const { minimum, maximum, gap, score } =
        this.form.statSegmentSettings[index];
      if (score === 0) {
        callback(new Error("请去考试管理移除该科目"));
      } else if (
        (minimum || String(minimum) == "0") &&
        (maximum || String(minimum) == "0") &&
        (gap || String(gap) == "0")
      ) {
        if (Number(minimum) > Number(maximum)) {
          callback(new Error("下限不能大于上限"));
        }
        if (minimum > score || maximum > score || gap > score) {
          callback(new Error("不能超过满分"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请全部填写参数"));
      }
    },
  },
};
export default mixin;
